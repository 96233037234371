import { CometChat } from '@cometchat-pro/chat';

// Replace these with your CometChat App credentials
const appID = 'YOUR_APP_ID';
const region = 'YOUR_REGION'; // e.g., "us" or "eu"
const apiKey = 'YOUR_API_KEY';

export const initializeCometChat = () => {
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();

  CometChat.init(appID, appSetting).then(
    () => {
      console.log('CometChat initialized successfully.');
    },
    (error) => {
      console.log('CometChat initialization failed:', error);
    }
  );
};
export const loginCometChatUser = (uid) => {
    return CometChat.login(uid, apiKey).then(
      (user) => {
        console.log('Login successful:', { user });
        return user;
      },
      (error) => {
        console.log('Login failed with error:', error);
      }
    );
  };
  
  export const registerCometChatUser = (uid, name) => {
    const user = new CometChat.User(uid);
    user.setName(name);
  
    return CometChat.createUser(user, apiKey).then(
      (user) => {
        console.log('User created successfully:', user);
        return loginCometChatUser(uid);
      },
      (error) => {
        console.log('Error creating user:', error);
      }
    );
  };
  

  
